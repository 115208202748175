import React, { Component } from 'react';
import styled from 'styled-components';
import { compose, pathOr } from 'ramda';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';

import { BLACK, DARK_GRAY_2, DARK_SCHEME } from '../constants/colors';
import withStickyHeader from '../enhancers/withStickyHeader';
import withCustomLayout from '../enhancers/withCustomLayout';
import {
  CONTAINER_LAPTOP_WIDTH, CONTAINER_WIDTH, MOBILE, TABLET, media, LAPTOP,
} from '../constants/screens';
import {
  Socials, BackButton, Content, Categories,
} from '../components/post';
import DisqusThread from '../components/post/disqusThread';

// eslint-disable-next-line
class PostTemplate extends Component {
  render() {
    const {
      data: {
        post: {
          wordpress_id: wordpressId,
          content,
          title,
          date,
          excerpt,
          comment_status: commentStatus,
          author: {
            name,
          },
          categories,
          featured_media: featuredMedia,
        },
      },
      location: {
        pathname,
      },
      pathContext: {
        shortname,
        domain,
        protocol,
      },
    } = this.props;

    const altText = pathOr('', ['alt_text'], featuredMedia);
    const sizes = pathOr(null, ['localFile', 'childImageSharp', 'sizes'], featuredMedia);
    const url = `${protocol}://${domain}${pathname}`;
    const isComments = commentStatus === 'open';

    return (
      <Page>
        <Container>
          <BackButton />
          { sizes && <StyledImg sizes={sizes} alt={altText} /> }
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <Meta>
            {name}
            {' '}
·
            {' '}
            {date}
          </Meta>
          <Content content={content} />
          { categories && <Categories categories={categories} /> }
          <Socials url={url} title={title} excerpt={excerpt} />
          {isComments
            && (
            <DisqusThread
              identifier={`${wordpressId} http://www.slashdata.co/?p=${wordpressId}`}
              url={url}
              title={title}
              shortname={shortname}
            />
            )
          }
        </Container>
      </Page>
    );
  }
}

PostTemplate.propTypes = {
  data: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
  pathContext: PropTypes.shape().isRequired,
};

export default compose(
  withStickyHeader({ isBlog: true }),
  withCustomLayout({ scheme: DARK_SCHEME }),
)(PostTemplate);

const Page = styled.article`
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 200px 5% 150px 5%;
  
   @media screen and (max-width: ${LAPTOP}) {
    padding: 150px 5% 120px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 120px 40px 80px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    padding: 120px 25px 60px;
  }
`;

const Title = styled.h1`
  font-size: 40px;
  font-weight: 900;
  color: ${BLACK};
  margin-bottom: 30px;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 30px;
    margin-bottom: 20px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 24px;
    margin-bottom: 25px;
  }
`;

const Meta = styled.div`
  font-family: AvenirNextLTPro;
  font-size: 19px;
  font-weight: 500;
  color: ${DARK_GRAY_2};
  margin-bottom: 40px;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 14px;
    margin-bottom: 30px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 12px;
    margin-bottom: 35px;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: ${CONTAINER_WIDTH};
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `}
`;

const StyledImg = styled(Img)`
  margin-bottom: 92px;
  
  @media screen and (max-width: ${LAPTOP}) {
    margin-bottom: 60px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    margin: 0 -40px 80px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    margin: 0 -25px 62px;
  }
`;

// eslint-disable-next-line
export const pageQuery = graphql`
  query PostPageQuery($id: String!) {
    post: wordpressPost(id: { eq: $id }) {
      wordpress_id
      excerpt
      link
      comment_status
      title
      content
      slug
      comment_status
      date(formatString: "MMMM DD, YYYY")
      author {
        name
        url
        description
        link
      }
      categories {
        name
        slug
        id
      }
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            sizes(maxWidth: 1400) {
              ...GatsbyImageSharpSizes_noBase64
            }
          }
        }
      }
    }
  }`;
