import React from 'react';
import { DiscussionEmbed } from 'disqus-react';
import PropTypes from 'prop-types';

const DisqusThread = ({
  url, identifier, title, shortname,
}) => (
  <DiscussionEmbed
    shortname={shortname}
    config={{
      url,
      title,
      identifier,
    }}
  />
);

DisqusThread.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
  shortname: PropTypes.string.isRequired,
};

export default DisqusThread;
