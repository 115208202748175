import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import Facebook from '../../svg/facebook-icon.svg';
import Twitter from '../../svg/twitter-icon.svg';
import Linkedin from '../../svg/linkedin-icon.svg';
import Share from '../../svg/share.svg';
import { BLUE, MEDIUM_GRAY } from '../../constants/colors';
import { LAPTOP, MOBILE } from '../../constants/screens';

const Socials = ({ url, title, excerpt }) => (
  <Container>
    <Icons>
      <ShareIcon />
      <FacebookShareButton
        url={url}
        quote={title}
      >
        <Icon isFacebook>
          <Facebook />
        </Icon>
      </FacebookShareButton>
      <TwitterShareButton
        url={url}
        title={title}
        via="SlashDataHQ"
      >
        <Icon>
          <Twitter />
        </Icon>
      </TwitterShareButton>
      <LinkedinShareButton
        url={url}
        title={title}
        description={excerpt}
      >
        <Icon>
          <Linkedin />
        </Icon>
      </LinkedinShareButton>
    </Icons>
  </Container>
);

Socials.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
};


export default Socials;

const Container = styled.div`
  padding: 30px 0;
  border-top: 2px solid ${MEDIUM_GRAY};
  border-bottom: 2px solid ${MEDIUM_GRAY};
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 25px 0;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    padding: 20px 0;
    border-width: 1px;
  }
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
  
  & > * {
    margin-right: 15px;
  }
`;

const Icon = styled.span`
  cursor: pointer;
  display: block;
  height: 30px;
  width: ${props => (props.isFacebook ? '15px' : '30px')};
  padding: 0;
  
  & svg {
    height: 100%;
    width: 100%;
    
    & path {
      fill: ${BLUE};
    }
  }
  
  @media screen and (max-width: ${LAPTOP}) {
    height: 22px;
    width: ${props => (props.isFacebook ? '11px' : '22px')};
  }
  
  @media screen and (max-width: ${MOBILE}) {
    height: 16px;
    width: ${props => (props.isFacebook ? '8px' : '16px')};
    margin-right: 10px;
  }
  
`;

const ShareIcon = styled(Share)`
  height: 30px;
  width: 30px;
  
  @media screen and (max-width: ${LAPTOP}) {
    height: 22px;
    width: 22px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    height: 16px;
    width: 16px;
    margin-right: 10px;
  }
  
  & path {
    fill: ${MEDIUM_GRAY};
  }
`;
