import React from 'react';
import styled from 'styled-components';
import Link from 'gatsby-link';
import { BLUE } from '../../constants/colors';
import ArrowBack from '../../svg/arrow-back.svg';
import { LAPTOP, MOBILE, TABLET } from '../../constants/screens';

const BackButton = () => (
  <Back to="/blog">
    <ArrowBack />
    <BackText>
        Back to blog
    </BackText>
  </Back>
);

const Back = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0 0 80px;
  
  > svg {
    width: 20px;
    height: 20px;
    
    & path {
      fill: ${BLUE};
    }
  }
  
  @media screen and (max-width: ${LAPTOP}) {
    margin: 0 0 60px;
    font-size: 16px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    margin: 0 0 40px;
    font-size: 14px;
  }
`;

const BackText = styled.p`
  font-family: AvenirNextLTProBold;
  font-size: 21px;
  letter-spacing: normal;
  text-align: left;
  color: ${BLUE};
  margin-left: 15px;
  
  @media screen and (max-width: ${TABLET}) {
     font-size: 17px;
  }
`;

export default BackButton;
